import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo.png'
import { useLocation } from 'react-router-dom'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Tillotts AB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    }
}));

export const ErrorPage = () => {
    const classes = useStyles();
    const [confirmationText, setConfirmationText] = React.useState({line1: '', line2: '', line3: ''});
    const location = useLocation();
    console.log(location.pathname);
    console.log(location);

    const oldLink = () => {
        setConfirmationText({line1: `Ooops, something went wrong. Please get back to Tillotts and ask for a new link`,
            line2: `Best Regards`,
            line3: 'Tillotts Nordic'})
    }


    useEffect( () => {
        oldLink();
    }, [])
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img alt="Tillotts Logo" src={logo}/>
                <hr/>
                <Typography component="h2" variant="h6">
                    {confirmationText.line1}
                    <br/>
                    <br/>
                    {confirmationText.line2}
                    <br/>
                    {confirmationText.line3}
                </Typography>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
