import React from 'react'
import {UploadConsent} from "./component/customer";
import {ErrorPage} from "./component/error";
import { Route, Switch } from "react-router-dom";
export const App = () => {
        return (
    <div className="App">
            <Switch>
                    <Route path="/customer/:id/:userId/upload/consent">
                            <UploadConsent/>
                    </Route>
                    <Route>
                            <ErrorPage/>
                    </Route>
            </Switch>
    </div>
)}
