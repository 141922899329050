import React from 'react'
import { render } from 'react-dom'
import {BrowserRouter} from "react-router-dom";
import {App} from "./App";
require('dotenv').config();
render(
    <React.StrictMode>
        <BrowserRouter basename='/'>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)
