import React, {useEffect, useState} from 'react';
import { useParams} from "react-router";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import {DropzoneArea} from 'material-ui-dropzone';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {Paper} from "@material-ui/core";
const axios = require('axios');
const endpoint = process.env.REACT_APP_API_ENDPOINT;
interface ConsentForm {
    id: number;
    email_primary: string;
    email_second?: string;
    phone?: string;
    mobile?: string;
    country: number;
    consentApprovalDate: Date | null ;
    file: string;
    filetype: string;
}

const emptyConsentForm: ConsentForm = {
    id: 0,
    email_primary: '',
    email_second: '',
    mobile: '',
    phone: '',
    country: 0,
    consentApprovalDate: new Date(),
    file: '',
    filetype: ''
};

const Form = styled.form`
    ${props =>
        props.submitted &&
        css`
                opacity: 0;
                height 0;
                display: none;
            `}
`
const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    },
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
}));

export const UploadConsent = () => {
    const classes = useStyles();
    const params = useParams();
    const [date, setDate] = useState(new Date());
    const [hideInput, setHideInput] = useState(true);
    const [confirmationText, setConfirmationText] = React.useState('');
    const [consentForm, setConsentForm] = React.useState<ConsentForm>(emptyConsentForm);
    const [emailPrimaryError, setEmailPrimaryError] = React.useState(false);
    const [emailSecondError, setEmailSecondError] = React.useState(false);
    const [iframeSrc, setIframeSrc] = useState('');
    const [imageSrc, setImageSrc] = useState('');

    const errorText = (text: string) => {
        setConfirmationText(text);
    }

    const handleFileDrop = async (files) =>{
        try {
            if (files.length>0) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = function (e) {
                    //Display uploaded file in iframe
                    if(file.type.indexOf('image') > -1) {
                        // @ts-ignore
                        setImageSrc(e.target.result);
                    } else {
                        // @ts-ignore
                        setIframeSrc(e.target.result);
                    }
                };
                reader.readAsDataURL(file);

                const folder = `customer/${params.id}/consent`;
                const filename = file.name;
                const filetype = file.type;
                const res = await axios.post(endpoint, {
                    object: 'customer',
                    id: params.id,
                    uuid: params.id,
                    userId: params.userId,
                    filename: file.name,
                    filetype: file.type
                });

                setHideInput(false);
                setConsentForm({...consentForm, file: `${res.data.key}`,filetype});
                const options = {
                    headers: {
                        'Content-Type': file.type
                    }
                };
                await axios.put(res.data.url, file, options);
            }
        } catch (e) {
            errorText(`Could not upload file: errors => ${e.toString()}`);
            alert(`Could not upload file: errors => ${e.toString()}`);
        }


    }
    const handleFieldChange = (event: React.ChangeEvent<{ value: string; name: string }>) => {
        setConsentForm({...consentForm, [event.target.name]: event.target.value});
    };
    const handleEmailChange = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        setConsentForm({...consentForm, [event.target.name]: event.target.value});
        if (!event.target.value && event.target.name === 'email_primary') {
            setEmailPrimaryError(true);
        } else if (!event.target.value && event.target.name !== 'email_primary'){
            setEmailSecondError(false);
        } else {
            if (re.test(String(event.target.value).toLowerCase())) {
                if (event.target.name === 'email_primary') {
                    setEmailPrimaryError(false);
                }
                else {
                    setEmailSecondError(false);
                }
            } else {
                if (event.target.name === 'email_primary')
                    setEmailPrimaryError(true);
                else
                    setEmailSecondError(true);
            }
        }

    };
    const handleDateChange = (selectedDate: Date | null ) => {
        // @ts-ignore
        setDate(selectedDate);

        setConsentForm({...consentForm, consentApprovalDate: selectedDate});
    };

    useEffect(() => {
        if (consentForm.file)
            window.top.postMessage({...consentForm, id: params.id, event: 'consentFormUpdate'},'*');
    },[consentForm, params.id]);
    return (
        <Grid container component="main"  className={classes.root} alignContent="stretch">
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
                <Form className={classes.form} autoComplete="off">
                    <Typography component="h3" variant="h5">
                        Follow these simple steps
                    </Typography>
                    <Typography component="h2" variant="body1">
                        1. Upload consent sent to you by the customer <br/>
                        2. Populate email/phone from the consent form <br/>
                        3. Submit <br/>
                    </Typography>
                    <br/>
                    <Grid item xs={12}>
                    <Grid container spacing={2} alignContent="space-around">
                        <DropzoneArea showAlerts={['error']}  filesLimit={1} showPreviews={false} showPreviewsInDropzone={false} onChange={handleFileDrop} />
                        {confirmationText}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={12}>
                                <KeyboardDatePicker
                                    required
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Consent approval date"
                                    autoOk={true}
                                    onChange={handleDateChange}
                                    value={date}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disabled={hideInput}
                                    onFocus={handleDateChange}
                                    onBlur={handleDateChange}

                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Primary email"
                                onChange={handleEmailChange}
                                error={emailPrimaryError}
                                name="email_primary"
                                value={consentForm.email_primary}
                                helperText={emailPrimaryError ? !consentForm.email_primary ? "Mandatory field": "Wrong format" : ""}
                                disabled={hideInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label="Secondary email"
                                name="email_second"
                                onChange={handleEmailChange}
                                error={emailSecondError}
                                value={consentForm.email_second}
                                helperText={emailSecondError ? "Wrong format" : ""}
                                disabled={hideInput}
                            />
                        </Grid>
                        <Grid item xs={12} className={consentForm.country === 5 ? classes.hidden : ""}>
                            <TextField
                                fullWidth
                                id="phone"
                                label='Phone'
                                name="phone"
                                onChange={handleFieldChange}
                                value={consentForm.phone}
                                disabled={hideInput}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="mobile"
                                label="Mobile"
                                name="mobile"
                                onChange={handleFieldChange}
                                value={consentForm.mobile}
                                disabled={hideInput}
                            />
                        </Grid>
                        <span>
                            *Required
                        </span>

                    </Grid>
                    </Grid>
                </Form>
            </div>
        </Grid>
            <Grid item xs={false} sm={4} md={7} >
                <iframe className={!iframeSrc ? classes.hidden : ""} title="Previewer" id="iframe" scrolling="yes" frameBorder="0" marginHeight = {0} marginWidth= {0} height="100%" width="100%"
                        allowFullScreen src={iframeSrc}>
                </iframe>
                <img alt='This is a previewer' className={!imageSrc ? classes.hidden : ""} style={{height: '100%', width: '100%'}} title="Previewer" id="consent_img"   src={imageSrc}></img>
            </Grid>
        </Grid>
    );
}
